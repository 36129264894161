import { normalize } from 'normalizr';
import { isEmpty, isNil } from 'lodash';
import { replace } from 'connected-react-router';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import request, { isInternalServerError } from '@/utils/request';
import { objectToParams } from '@/utils/url';
import { trackRecruiterClickedNylasConnect, trackRecruiterClickedNylasDisconnect, trackError } from '@/utils/analytics';
import * as actions from './actions';
import * as actionsAuth from '../auth/actions';
import { patchExponent, patchExponentError } from '../exponent/actions';
import { notificationActions } from '../notification';
import * as types from './types';
import { userListSchema, userSchema } from './schema';
import { entitiesActions } from '../entities';
import { authSelectors, authActions } from '../auth';
import { participantSelectors } from '../participant'
import { resetParticipant } from '../participant/actions';
import { offerActions } from '../offer';
import { appActions } from '../app';
import { organizationActions } from '../organization';
import * as userActions from './actions';

/**
 * Get an user
 */
function* getUser() {
  const requestUrl = `${process.env.FRONT_API_URL}/auth/me`;

  try {
    const result = yield call(request, requestUrl, {
      method: 'GET',
    });

    yield put(authActions.authRefreshUser({ user: result }));

  } catch (err) {
    trackError(err);
    
    if (err.code === 401) {
      yield put(appActions.setFetchError());
    }
    if (err.code === 404) {
      sessionStorage.clear()
      localStorage.clear();
      yield put(actionsAuth.authLogoutUser());
      yield put(resetParticipant());
    }
  }
}

function* deleteUser({ payload: { userId, userParams, noLogout, callback, organizationId, isCandidate = false } }) {
  const requestUrl = `${process.env.FRONT_API_URL}/users/${userId}?reason=${userParams ? userParams.reason : ''}&messages=${userParams ? userParams.messages : ''}`;
  const requestUrl2 = `${process.env.FRONT_API_URL}/organizations/${organizationId}/users/${userId}`;
  const authToken = yield select(authSelectors.getAuthToken);

  // Patch the user
  try {
    yield call(request, isCandidate ? requestUrl : requestUrl2, {
      method: 'DELETE',
      body: JSON.stringify(userParams),
    });

    // Logout user
    if (typeof callback === 'function') {
      callback();
    }

    yield put(userActions.deleteUserSuccess());

    if (noLogout !== true) {
      yield put(authActions.authLogout({ redirectV1: true }));
    }
  } catch (err) {
    trackError(err);
  }
}

function* patchUser({ payload: { userId, userParams, notificationParams, resetStatus, noRefresh, callback } }) {
  const requestUrl = `${process.env.FRONT_API_URL}/users/${userId}`;
  const authUser = yield select(authSelectors.getAuthUser);

  // Patch the user
  try {
    const result = yield call(request, requestUrl, {
      method: 'PATCH',
      headers: {
        KoaUpdateCache: 'true',
      },
      body: JSON.stringify(userParams),
    });

    // Handle patchUser from team management
    if (!noRefresh) {
      const dataNormalized = normalize(result, userSchema);

      // Save entities
      yield put(entitiesActions.replaceEntities({ id: userId, type: 'users', entities: dataNormalized.entities }));
      yield put(entitiesActions.mergeEntities(dataNormalized.entities));

      if (authUser && authUser.get('_id') === result._id) {
        yield put(authActions.authRefreshUser({ user: result }));
      }
    }

    if (notificationParams && !isEmpty(notificationParams.success)) {
      yield put(notificationActions.sendNotification({
        message: notificationParams.success.message,
        kind: notificationParams.success.kind,
        style: notificationParams.success.style,
      }));
    }

    if (typeof callback === 'function') {
      callback();
    }

    if (resetStatus) {
      yield put(authActions.authResetPasswordStatus(resetStatus));
    }

    yield put(actions.patchUserSuccess());
  } catch (err) {
    trackError(err);

    if (typeof callback === 'function') {
      callback(err);
    }
    if (!isInternalServerError(err.code) && notificationParams?.error !== false) {
      yield put(notificationActions.sendNotification({
        message: notificationParams.error?.message ?? err.message,
        kind: 'error',
        style: notificationParams.error?.style ?? {},
      }));
    }
  }
}

function* patchUsers({ payload: { usersIds, newRecruiters, userParams, notificationParams, resetStatus, context, callback } }) {
  const requestUrl = `${process.env.FRONT_API_URL}/users`;

  try {
    yield call(request, requestUrl, {
      method: 'PATCH',
      body: JSON.stringify({ ...userParams, usersIds, newRecruiters, context }),
    });

    if (notificationParams && !isEmpty(notificationParams.success)) {
      yield put(notificationActions.sendNotification({
        message: notificationParams.success.message,
        kind: notificationParams.success.kind,
        style: notificationParams.success.style,
      }));
    }

    if (typeof callback === 'function') {
      callback();
    }

    if (resetStatus) {
      yield put(authActions.authResetPasswordStatus(resetStatus));
    }

    yield put(actions.patchUserSuccess());
  } catch (err) {
    trackError(err);

    if (!isInternalServerError(err.code)) {
      yield put(notificationActions.sendNotification({
        message: err.message,
        kind: 'error',
        style: {},
      }));
    }
  }
}

/**
 * Put an user after signup
 *
 * @param {string} userId
 * @param {object} userParams
 * @param {string} eventSlug
 * @param {string} offerId
 * @param {string} participantId
 */
function* putUserAfterSignup({ payload: { userId, userParams, eventSlug, offerId, participantId } }) {
  const requestUrl = `${process.env.FRONT_API_URL}/users/${userId}`;

  const participantList = yield select(participantSelectors.getParticipantsIds);
  const participantIds = participantList.toArray();
  const userToPut = { ...userParams, participantIds };

  try {
    const result = yield call(request, requestUrl, {
      method: 'PUT',
      body: JSON.stringify(userToPut),
    });

    const dataNormalized = normalize(result, userSchema);

    yield put(authActions.authRefreshUser({ user: result }));

    yield put(entitiesActions.replaceEntities({ id: userId, type: 'users', entities: dataNormalized.entities }));
    yield put(entitiesActions.mergeEntities(dataNormalized.entities));

    if (!isNil(offerId) && !isNil(participantId)) {
      yield put(offerActions.applyOffer({ offerId, eventId: eventSlug, participantId }));
    }

    yield put(actions.patchUserSuccess());
  } catch (err) {
    trackError(err);
  }
}

function* patchUserBeforeSignup({ payload: { userId, userParams, callback } }) {
  const requestUrl = `${process.env.FRONT_API_URL}/users/${userId}`;
  const userToPut = { ...userParams };

  try {
    const result = yield call(request, requestUrl, {
      method: 'PATCH',
      body: JSON.stringify(userToPut),
    });

    const dataNormalized = normalize(result, userSchema);

    yield put(authActions.authRefreshUser({ user: result }));
    yield put(entitiesActions.replaceEntities({ id: userId, type: 'users', entities: dataNormalized.entities }));
    yield put(entitiesActions.mergeEntities(dataNormalized.entities));

    if (typeof callback === 'function') {
      callback();
    }

    yield put(actions.patchUserSuccess());
  } catch (err) {
    trackError(err);
  }
}

function* getUsersOrganization({ payload: { organizationId, search = {}, light = null, stats = false, callback } }) {
  const searchTmp = search;

  if (light === true) {
    searchTmp.light = true;
  }

  if (stats) {
    searchTmp.stats = true;
  }

  if (!search.limit) {
    searchTmp.limit = 1000;
  }

  const searchParams = objectToParams(searchTmp);

  const requestUrl = `${process.env.FRONT_API_URL}/organizations/${organizationId?.toString()}/users?${searchParams}&sort=lastName`;

  try {
    const result = yield call(request, requestUrl, {
      method: 'GET',
    });

    const dataNormalized = normalize(result.docs, userListSchema);

    yield put(entitiesActions.mergeEntities(dataNormalized.entities));
    yield put(organizationActions.setCurrentOrganization(organizationId));
    yield put(actions.getUsersOrganizationSuccess({
      organizationId,
      ...result,
      docs: dataNormalized.result,
    }));

    if (typeof callback === 'function') {
      callback();
    }
  } catch (err) {
    trackError(err);

    if (!isInternalServerError(err.code)) {
      yield put(notificationActions.sendNotification({
        message: err.message,
        kind: 'error',
        style: {},
      }));
    }
  }
}

function* postUserOrganization({ payload: { organizationId, users, userParams = {}, notificationParams, callback } }) {
  const requestUrl = `${process.env.FRONT_API_URL}/organizations/${organizationId}/users/add`;

  try {
    const result = yield call(request, requestUrl, {
      method: 'POST',
      body: JSON.stringify({
        users,
        userParams,
      }),
    });

    const dataNormalized = normalize(result, userSchema);

    if (typeof callback === 'function') {
      callback();
    }
    yield put(entitiesActions.mergeEntities(dataNormalized.entities));
    yield put(actions.postUserOrganizationSuccess({
      organizationId,
      result: dataNormalized.result,
    }));

    if (notificationParams && notificationParams.success) {
      yield put(notificationActions.sendNotification({
        message: notificationParams.success.message,
        kind: notificationParams.success.kind,
        style: notificationParams.success.style,
      }));
    }
  } catch (err) {
    trackError(err);
  }
}

function* postOrganizationUserAndAddToExponent({ payload: { organizationId, exponent, event, userParams, notificationParams, callback } }) {
  const requestUrl = `${process.env.FRONT_API_URL}/organizations/${organizationId}/users`;
  const body = userParams;

  body.eventSlug = event.slug;

  try {
    const result = yield call(request, requestUrl, {
      method: 'POST',
      body: JSON.stringify(body),
    });

    const dataNormalized = normalize(result, userSchema);

    yield put(entitiesActions.mergeEntities(dataNormalized.entities));

    yield put(patchExponent({
      eventId: exponent._event._id,
      exponentId: exponent._id,
      lean: true,
      exponentParams: {
        newUser: result,
      },
      callback: () => {
        if (typeof callback === 'function') {
          callback();
        }
      }
    }));

    if (notificationParams && notificationParams.success) {
      yield put(notificationActions.sendNotification({
        message: notificationParams.success.message,
        kind: notificationParams.success.kind,
        style: notificationParams.success.style,
      }));
    }
  } catch (err) {
    console.log(err); // eslint-disable-line
    trackError(err);

    const errorMessage = err && err.response ? yield err.response.text() : yield '';
    const errorData = JSON.parse(errorMessage);

    if (notificationParams.error && errorData) {
      yield put(patchExponentError({
        message: notificationParams.error.message.replace('%organization%', errorData.message),
      }));
    }
  }
}

function* getUserLastParticipation({ payload: { userId } }) {
  const requestUrl = `${process.env.FRONT_API_URL}/users/${userId}/last-participation`;

  try {
    const result = yield call(request, requestUrl, {
      method: 'GET',
    });

    yield put(actions.getUserLastParticipationSuccess({
      result: !isEmpty(result) ? result : null,
    }));
  } catch (err) {
    trackError(err);
  }
}

function* resetDemonstration() {
  const requestUrl = `${process.env.FRONT_API_URL}/apps/initDemo`;

  try {
    yield call(request, requestUrl, {
      method: 'POST',
    });
  } catch (err) {
    trackError(err);
  }
}

function* switchOrganization({ payload: { organizationId, notificationParams, callback } }) {
  const authUser = yield select(authSelectors.getAuthUser);
  const userId = authUser.get('_id');

  const requestUrl = `${process.env.FRONT_API_URL}/users/${userId}/switchOrganization`;

  try {
    const result = yield call(request, requestUrl, {
      method: 'POST',
      body: JSON.stringify({ organizationId }),
    });

    const dataNormalized = normalize(result, userSchema);

    yield put(authActions.authRefreshUser({ user: result }));

    if (notificationParams && notificationParams.success) {
      yield put(notificationActions.sendNotification({
        message: notificationParams.success.message,
        kind: notificationParams.success.kind,
        style: notificationParams.success.style,
      }));
    }

    if (typeof callback === 'function') {
      callback();
    }

    yield put(entitiesActions.replaceEntities({ id: userId, type: 'users', entities: dataNormalized.entities }));
    yield put(entitiesActions.mergeEntities(dataNormalized.entities));
  } catch (err) {
    trackError(err);
  }
}

function* deleteUserFromOrganization({ payload: { organizationId, userId, notificationParams, callback } }) {
  const requestUrl = `${process.env.FRONT_API_URL}/users/${userId}/removeOrganization`;

  try {
    const result = yield call(request, requestUrl, {
      method: 'POST',
      body: JSON.stringify({ organizationId }),
    });

    const dataNormalized = normalize(result, userSchema);

    if (notificationParams && notificationParams.success) {
      yield put(notificationActions.sendNotification({
        message: notificationParams.success.message,
        kind: notificationParams.success.kind,
        style: notificationParams.success.style,
      }));
    }

    if (typeof callback === 'function') {
      callback();
    }

    yield put(entitiesActions.replaceEntities({ id: userId, type: 'users', entities: dataNormalized.entities }));
    yield put(entitiesActions.mergeEntities(dataNormalized.entities));
  } catch (err) {
    trackError(err);
  }
}

function* resendInvitationEmail({ payload: { user, eventId, notificationParams } }) {
  const requestUrl = `${process.env.FRONT_API_URL}/users/sendinvitationtoken`;

  try {
    yield call(request, requestUrl, {
      method: 'POST',
      body: JSON.stringify({ email: user.username, eventId }),
    });

    if (notificationParams && notificationParams.success) {
      yield put(notificationActions.sendNotification({
        message: notificationParams.success.message,
        kind: notificationParams.success.kind,
        style: notificationParams.success.style,
      }));
    }
  } catch (err) {
    trackError(err);
  }
}

function* getSyncConnectUrl() {
  const authUser = yield select(authSelectors.getAuthUser);

  const requestUrl = `${process.env.FRONT_API_URL}/users/calendar/connect`;

  window.localStorage.setItem('syncRedirectUrl', window.location.pathname);

  try {
    const result = yield call(request, requestUrl, {
      method: 'POST',
      body: JSON.stringify({}),
    });

    trackRecruiterClickedNylasConnect({ authUser: authUser.toJS() });

    if (result.authUrl) {
      window.location.href = result.authUrl;
    }
  } catch (err) {
    trackError(err);
  }
}

function* getSyncDisconnectUrl() {
  const requestUrl = `${process.env.FRONT_API_URL}/users/calendar/disconnect`;

  window.localStorage.setItem('syncRedirectUrl', window.location.pathname);

  try {
    const { user, calendars } = yield call(request, requestUrl, {
      method: 'POST',
      body: JSON.stringify({}),
    });
    const dataNormalized = normalize(user, userSchema);
    if (user && user._id) {
      trackRecruiterClickedNylasDisconnect({ authUser: user });

      yield put(entitiesActions.replaceEntities({ id: user._id, type: 'users', entities: dataNormalized.entities }));
      yield put(entitiesActions.mergeEntities(dataNormalized.entities));
      yield put(authActions.authRefreshUser({ user }));
      yield put(userActions.setCalendars({ calendars }));
    }
  } catch (err) {
    trackError(err);
  }
}

function* postSyncCode({ payload: { code } }) {
  const requestUrl = `${process.env.FRONT_API_URL}/users/calendar/oauth`;

  const redirectUrl = window.localStorage.getItem('syncRedirectUrl');

  try {
    const { user, calendars } = yield call(request, requestUrl, {
      method: 'POST',
      body: JSON.stringify({ code }),
    });

    const dataNormalized = normalize(user, userSchema);

    if (user && user._id) {
      yield put(entitiesActions.replaceEntities({ id: user._id, type: 'users', entities: dataNormalized.entities }));
      yield put(entitiesActions.mergeEntities(dataNormalized.entities));
      yield put(authActions.authRefreshUser({ user }));
    }

    yield put(userActions.setCalendars({ calendars }));

    yield put(authActions.toggleSettings());
    yield put(replace(redirectUrl));
  } catch (err) {
    trackError(err);
  }
}

function* getCalendars() {
  const requestUrl = `${process.env.FRONT_API_URL}/users/calendar/list`;

  try {
    const result = yield call(request, requestUrl, {
      method: 'GET',
    });

    yield put(userActions.setCalendars({ calendars: result }));
  } catch (err) {
    trackError(err);
  }
}

function* patchMe({ payload: { userParams, notificationParams, resetStatus, noRefresh, callback } }) {
  const requestUrl = `${process.env.FRONT_API_URL}/auth/me`;

  try {
    const result = yield call(request, requestUrl, {
      method: 'PATCH',
      headers: {
        KoaUpdateCache: 'true',
      },
      body: JSON.stringify(userParams),
    }, [], true);

    yield put(authActions.authRefreshUser({ user: result }));

    if (notificationParams && !isEmpty(notificationParams.success)) {
      yield put(notificationActions.sendNotification({
        message: notificationParams.success.message,
        kind: notificationParams.success.kind,
        style: notificationParams.success.style,
      }));
    }

    if (typeof callback === 'function') {
      callback();
    }

    if (resetStatus) {
      yield put(authActions.authResetPasswordStatus(resetStatus));
    }
  } catch (err) {
    trackError(err);

    if (typeof callback === 'function') {
      err.then(e => callback(e));
    }
    if (!isInternalServerError(err.code)) {
      yield put(notificationActions.sendNotification({
        message: notificationParams.error?.message ?? err.message,
        kind: notificationParams.error?.kind ?? 'error',
        style: notificationParams.error?.style ?? {},
      }));
    }
  }
}

/**
 * Listen Actions
 */
export default [
  takeLatest(types.GET_USERS_ORGANIZATION, getUsersOrganization),
  takeLatest(types.SWITCH_ORGANIZATION, switchOrganization),
  takeLatest(types.PATCH_USER, patchUser),
  takeLatest(types.PATCH_ME, patchMe),
  takeLatest(types.PATCH_USERS, patchUsers),
  takeLatest(types.DELETE_USER, deleteUser),
  takeLatest(types.PUT_USER_AFTER_SIGNUP, putUserAfterSignup),
  takeLatest(types.PATCH_USER_BEFORE_SIGNUP, patchUserBeforeSignup),
  takeLatest(types.POST_USER_ORGANIZATION, postUserOrganization),
  takeLatest(types.POST_USER_ORGANIZATION_AND_ADD_TO_EXPONENT, postOrganizationUserAndAddToExponent),
  takeLatest(types.GET_USER, getUser),
  takeLatest(types.GET_USER_LAST_PARTICIPATION, getUserLastParticipation),
  takeLatest(types.RESET_DEMONSTRATION, resetDemonstration),
  takeLatest(types.RESEND_INVITATION_EMAIL, resendInvitationEmail),
  takeLatest(types.DELETE_USER_FROM_ORGANIZATION, deleteUserFromOrganization),
  takeLatest(types.GET_SYNC_CONNECT_URL, getSyncConnectUrl),
  takeLatest(types.GET_SYNC_DISCONNECT_URL, getSyncDisconnectUrl),
  takeLatest(types.POST_SYNC_CODE, postSyncCode),
  takeLatest(types.GET_CALENDARS, getCalendars),
];
