/**
 * App constants
 */
export const RESTART_ON_REMOUNT = '@@saga-injector/restart-on-remount';
export const DAEMON = '@@saga-injector/daemon';
export const ONCE_TILL_UNMOUNT = '@@saga-injector/once-till-unmount';

const EVENT_STATUS = {
  IN_PROGRESS: 'in_progress',
  HUNT_POST_EVENT: 'hunt_post_event', // Hunt post event
  PREREGISTRATION: 'preregistration',
  DISCOVERING: 'discovering',
  OPEN: 'open', // Access for candidate and recruiter,
  CLOSED: 'closed',
};

const EVENT_FORMAT = {
  VIRTUAL: 'virtual',
  PHYSICAL: 'physical',
  HYBRID: 'hybrid',
};

const EVENT_TYPE = {
  jpo: 'jpo',
  seekube: 'seekube',
  school: 'school',
  company: 'company',
};

const DASHBOARD_MODE = {
  STATIC: 'static',
  DYNAMIC: 'dynamic',
};

const INTERVIEW_CONTEXTS = ['unconfirmed', 'confirmed', 'interview', 'refused'];


const CONTEXT_URLS = {
  confirmed: 'interview/confirmed',
  unconfirmed: 'interview/unconfirmed',
  interview: 'interview/interview',
  refused: 'interview/refused',
  score0: 'refuse',
  score1: 'save',
  score2: 'top',
};

/**
 * Disable service for specific companies
 */
const DISABLE_SERVICE_FOR_COMPANIES = {
  INTERCOM: ['orano', 'orano-group'],
};

const ENABLE_SERVICE_FOR_TEST = {
  SYNC_CALENDAR: ['subscription', 'welcomePack', 'oneShot'],
  SYNC_CALENDAR_USERS: [
    "cofidisfrance@cofidis.com",
    "creatis@cofidis.com",
    "synergie@cofidis.com",
    "helene.vanoverberghe@cofidis-group.com",
    "manon.mondon@cofidis-group.com",
    "alexandrine@sixfeetover.fr",
    "candice@sixfeetover.fr",
    "alexandra.dubec@cofidis-group.com",
    "isabelle.agacinski@cofidis-group.com",
    "gwladys.guelque@cofidis-group.com",
    "tahina.holle@cofidis-group.com",
    "ninon.poulainfournier@cofidis-group.com",
    "caroline.sturni@cofidis-group.com",
    "agathe.roose@cofidis-group.com",
    "ursula.walrawens@cofidis-group.com",
    "anais.davigny@cofidis-group.com",
    "thibault.lobry@cofidis-group.com",
    "alexandre.orliaguet@seekube.com",
    "nina.castino.int@businessfrance.fr",
    "severine.martin@manpower.fr",
    "philippe.cassan@decathlon.com",
    "romain.pedoussaut@decathlon.com",
    "jeanchristophe.arnaud@decathlon.com",
    "sophie.jacquinot@decathlon.com",
    "clarisse.darnault@decathlon.com",
    "federica.bambagini@sfr.com",
    "noemie.martel@sfr.com",
    "camille.vila@sfr.com",
    "mafanta.diakite@sfr.com",
    "manon.jolivet@sfr.com",
    "isabelle.delattrehaelewyn@sfr.com",
    "jonathan.zanovellogonnet@sfr.com",
    "oliver.arroyo@sfr.com",
    "margaux_schurb@carrefour.com",
    "mathilde_renoux@carrefour.com",
    "laurine_irlande@carrefour.com",
    "zainab_edoo@carrefour.com",
    "olivier.maurel@ca-languedoc.fr",
    "patrick.brusley@levillagebyca.com",
    "florence.sergue@ca-languedoc.fr",
    "marieline.vantheemsche@ca-languedoc.fr",
    "chantal.valette@ca-languedoc.fr",
    "edouard.alvarez@ca-languedoc.fr",
    "agathe.vincourt@castorama.fr",
    "liece@talentumsolutions.fr",
    "sandrina.vieira@akzonobel.com",
    "anais.maguet@akzonobel.com",
    "anton.gruenwald@akzonobel.com",
    "fhipolite@id-logistics.com",
    "beatrice.fontana@cic.fr",
    "geraldine.kempf@cic.fr",
    "sacha.hette@skilleos.com",
    "vincent.amiot@qapa.com",
    "alexia.olendo@qapa.com",
    "loise.notablebuschgens@adeccogroup.com",
    "hassana.baradi@adeccogroup.com",
    "lea.blanchard@adeccogroup.com",
    "theo.dirosa@adeccogroup.com",
    "thierry.bourot@adecco.fr",
    "vvaugeois@finaxys.com",
    "severine.blasquez@cic.fr",
    "lydie.lau@cic.fr",
    "bernard.casabona@cic.fr",
    "quentin.croise@sodexo.com",
    "amelie.louyer@sodexo.com",
    "erika.faucret@sodexo.com",
    "nicolas.boillot@sodexo.com",
    "chloe.gillieron@sodexo.com",
    "claire.giraud@sodexo.com",
    "mcdugain@oneside.fr",
    "e.mahfoudhi@bouygues-es.com",
    "margot.champel@leroymerlin.fr",
    "pauline.aupet@ehi.com",
    "sarah.vigier@chaumes.fr",
    "margaux.hude@generali.com",
    "jmallet@externe.generali.fr",
    "atimmermans@externe.generali.fr",
    "vmascrez@externe.generali.fr",
    "nolwenn.garrec@ferrero.com",
    "sully.pierron@oracle.com",
    "vincent.rinner@oracle.com",
    "claire@matchers.fr",
    "victoria.ruiz@ge.com",
    "guilhem.rouanet@ge.com",
    "alban.thiolet@ge.com",
    "camille.le.deun@accenture.com",
    "victor.landrivon@accenture.com",
    "issam.himmi@accenture.com",
    "anais.salas@accenture.com",
    "l.larcher@accenture.com",
    "rachel_kanoui@dell.com",
    "mhkaminski@extia.fr",
    "odemontety@extia.fr",
    "rkahn@extia.fr",
    "idelphin@extia.fr",
    "jgoulay@extia.fr",
    "mrobert@extia.fr",
    "aclouet@extia.es",
    "lleboucher@extia.es",
    "cdelarocque@extia.fr",
    "thea.pigache@royalcanin.com",
    "clarisse.passama1@royalcanin.com",
    "kamilya.moukahal@royalcanin.com",
    "cassandre.toussaint@royalcanin.com",
    "ragitha.paramalingam@swisslife.fr",
    "marine.gonalons@ca-alpesprovence.fr",
    "amatregl@bouyguestelecom.fr",
    "mchenin@bouyguestelecom.fr",
    "mcohen@bouyguestelecom.fr",
    "eraveau@bouyguestelecom.fr",
    "tszymbor@bouyguestelecom.fr",
    "milazreg@bouyguestelecom.fr",
    "cgueury@fr.scc.com",
    "celine.eymard@ca-centrefrance.fr",
    "lea.vinel@klesia.fr",
    "daisy.keobounnam@klesia.fr",
    "laura.decramer@converteo.com",
    "anne-sophie.manach@converteo.com",
    "mathilde.la-perna@converteo.com",
    "pierre-sacha.gouret@converteo.com",
    "vanessa.guyot@converteo.com",
    "luc.wang@converteo.com",
    "charlotte.clement@converteo.com",
    "jessica.vacher@lidl.fr",
    "pierre_yves.rozand@lidl.fr",
    "audric.joannard@haribo.com",
    "najeth.geuffroy@amadeus.com",
    "ana.arribas@amadeus.com",
    "caroline.colombo@amadeus.com",
    "nora.chelabi@amadeus.com",
    "cmallet@amadeus.com",
    "sophie.remy@hanes.com",
    "emilie.hugounenc0580@reseau-intersport.fr",
    "contact@nipsi.io",
    "m.tafaro@nipsi.io",
    "pcinquin@ayming.com",
    "hannah.kolesnikoff@groupe-crit.com",
    "cathelyne.varloteaux@hp.com",
    "aurore@stride-up.fr",
    "victoire@stride-up.fr",
    "lucas@stride-up.fr",
    "julien@stride-up.fr",
    "hugues@stride-up.fr",
    "laetitia.nayagom@solinest.fr",
    "chloe.bourrette@aesio.fr",
    "audrey.vallet@aesio.fr",
    "delphine.depinoy@aesio.fr",
    "sara.sanhaji@aesio.fr",
    "esther.alves@valeo.com",
    "melanie.gardies@valeo.com",
    "hawa.bathily@valeo.com",
    "guillemette.bouquet-des-chaux@valeo.com",
    "vanessa.siliphayvanh@valeo.com",
    "isaure.devibraye@stmichel.fr",
    "romain.burmann@cepac.caisse-epargne.fr",
    "mathilde.delorme@cepac.caisse-epargne.fr",
    "david.lauren@urbasolar.com",
    "m.lecerf@hays.fr",
    "l.khalidi@optimum-automotive.com",
    "c.cogo@optimum-automotive.com",
    "elsa@privatesportshop.com",
    "aureliethibault@michaelpage.fr",
    "ismaelsilue@pagepersonnel.fr",
    "sylvain.raynal@fr.gt.com",
    "julie.nesci@fr.gt.com",
    "basile.vidal@fr.gt.com",
    "laurene.burg@melisana-pharma.fr",
    "claudia.rasolonirina@melisana-pharma.fr",
    "cdepadova@peoplespheres.com",
    "delphine.wilbert@rmm-co.com",
    "zouhaira.sahtout@rmm-co.com",
    "tom.klis@rmm-co.com",
    "alison.adegas.alves@schindler.com",
    "mathilde.jia@schindler.com",
    "fy-tia.rakotosalama@transdev.com",
    "a.pillot@barjane.com",
    "ho.ccarloz@cma-cgm.com",
    "ho.mlempereur@cma-cgm.com",
    "gestionforumsrecrutement.fr@capgemini.com",
    "myriam.chetto@capgemini.com",
    "sara.fahsi@capgemini.com",
    "beatrice.castel@orange.com",
    "mdubois@bessand.com",
    "s.belaredj@vacanceselect.com",
    "r.roger@vacanceselect.com",
    "vanessa.coliche@groupe-aplitec.com",
    "manon.bouchez@adsearch.fr",
    "mcarrizo@auchan.fr",
    "paulina.kurzawa.hr@pl.mccormick.com",
    "dominika.terczynska@pl.mccormick.com",
    "schihat@fr.mccormick.com",
    "gbeney@groupeseb.com",
    "rmontigni@groupeseb.com",
    "anais.dubuc@kone.com",
    "soukaina.elmousaid@kone.com",
    "nawal.khettab@kone.com",
    "magali.miclos@derichebourg.com",
    "marine.garrido@derichebourg.com",
    "laurent.soula@rexel.fr",
    "margaux.bedel@galec.leclerc",
    "pierre.josse@galec.fr",
    "delphine.ferret@galec.fr",
    "alicja.vandecauter@galec.leclerc",
    "laurie.oms@celr.caisse-epargne.fr",
    "clemence.galloux@celr.caisse-epargne.fr",
    "samar@baybridgedigital.com",
    "svandermeersch@baybridgedigital.com",
    "karinemartinez@esteban.fr",
    "yderre@fr.petitbateau.com",
    "vincent.noisette@inetum.com",
    "aurelie.vieren@inetum.com",
    "melissa.coulibaly@inetum.com",
    "guilhem.betschen@inetum.com",
    "penda.cisse@inetum.com",
    "jean-michel.fanchin@inetum.com",
    "elise.schemid@inetum.com",
    "melissa.ait-ouaret@inetum.com",
    "adele.quemener@malt.com",
    "elisa.soto@malt.com",
    "aya.ghazisnoussi@malt.com",
    "emmanuel.martin@loreal.com",
    "sebastien.weber@loreal.com",
    "yue.chen@loreal.com",
    "armelle.plas@loreal.com",
    "emilie.magnien@loreal.com",
    "antoine.gleron@loreal.com",
    "margaux.velty@loreal.com",
    "laura.gamaleia@loreal.com",
    "laura.gamaleia2@loreal.com",
    "carine.afro@edenred.com",
    "delphine.giscarddestaing@edenred.com",
    "vincent.chin.thing.fung@hsbc.fr",
    "b-jessin@dualis.fr",
    "contact@shefferd.com",
    "mmercier@shefferd.com",
    "marie.prevosto@momgroup.com",
    "tbourlon@hellowork.com",
  ],
  SYNC_OUTLOOK_USERS: []
};

const WORDS_IN_SLUG_FOR_WOMEN_EVENT = ['femme', 'feminin'];

const PARTICIPANT_ROLE = {
  candidate: 'candidate',
  recruiter: 'recruiter',
};

const USERS_EVENT_ROLES = {
  public: 'public',
  guest: 'guest',
  recruiter: 'recruiter',
  participant: 'participant',
  owner: 'owner',
  admin: 'admin'
};

const USERS_TYPE = {
  candidate: 'candidate',
  recruiter: 'recruiter',
  owner: 'owner',
  admin: 'admin',
};

const USER_ROLE = {
  PARTICIPANT: 'participant',
  RECRUITER: 'recruiter',
  EVENT_OWNER: 'eventOwner',
  SEEKUBE: 'seekube',
  MANAGER: 'manager'
};

const LINKS = {
  intercom_help_candidate: {
    fr: 'https://intercom.help/seekube/fr/collections/2627090-je-suis-candidat',
    en: 'https://intercom.help/seekube/en/collections/2627090-i-am-a-candidate',
  },
  intercom_help_recruiter: {
    fr: 'https://intercom.help/seekube/fr/collections/2627091-je-suis-recruteur',
    en: 'https://intercom.help/seekube/en/collections/2627091-i-am-recruiter',
  }
};

const ANALYTICS_ORGA = {
  MODIFIED_KEY_DATES: 'Modified keyDates',
  RESET_REPORTINGS: 'Clicked on reset reportings dates',
  ENABLE_REPORTINGS: 'Clicked on enable reportings',
  DISABLE_REPORTINGS: 'Clicked on disable reportings'
};

const ANALYTICS_RECRUITER = {
  CLICKED_VIEW_KEY_DATES: 'Clicked View key dates (pro)',
  CLICKED_VIEW_ALL_APPOINTMENTS: 'Clicked View All Appointments',
  CLICKED_FORUM: 'Clicked See Forum (pro)',
  DOWNLOADED_SEEKUBE_CALENDAR: 'Downloaded Seekube Calendar (pro)',
  EXPORTED_APPOINTMENT_CALENDAR: 'Exported Appointment Calendar (pro)',
  CHANGED_APPOINTMENT: 'Changed Appointment (pro)',
  CLICKED_CHANGE_APPOINTMENT: 'Clicked change appointement (pro)',
  CANCELED_APPOINTMENT: 'Canceled Appointment (pro)',
  SEARCHED_FOR_CANDIDATES: 'Searched for Candidates',
  DISPLAYED_CONTACT_INFO: 'Displayed Contact Info',
  ADDED_TO_TREATED_PROFILES: 'Added to "Profils Traités',
  MOVE_PROFILES: 'Move to (pro)',
  HID_CANDIDATES: 'Hid Candidates',
  REFUSED_CANDIDATES: 'Refused Candidates',
  DOWNLOADED_CV: 'Downloaded CV',
  EDITED_STARS: 'Edited Stars (pro)',
  DELETE_SLOTS: 'Deleted Availabilities (pro)',
  CREATED_SLOTS: 'Created availabilities (pro)',
  CLICKED_CREATED_SLOTS: 'Clicked create availabilities (pro)',
  QUIT_CREATING_SLOTS: 'Quit creating availabilities (pro)',
  CLICKED_QUIT_CREATING_SLOTS: 'Clicked quit creating availabilities (pro)',
  EDIT_ORGANIZATION_PROFILE: 'Edited Company Profile (pro)',
  CLICKED_FEEDBACK_LATER: 'Clicked later',
  CLICKED_FEEDBACK_NO_SHOW: 'Clicked no show',
  CLICKED_CREATED_OFFER: 'Clicked Created Offer (pro)',
  CLICKED_EXISTING_OFFER: 'Created Existing Offer (pro)',
  SEARCHED_OFFERS_BY_EVENTS: 'Searched Offers by Event (pro)',
  CLICKED_SAVE_STAND: 'Clicked save stand (pro)',
  CLICKED_PUBLISH_STAND: 'Clicked publish stand (pro)',
  CLICKED_VIEW_PROFILE: 'Clicked view profile (pro)',
  EDIT_SLOTS: 'Edited Availabilities (pro)',
  EDIT_PROFILE: 'Edited Profile (pro)',
  EDITED_SEND_INVITATION: 'Edited send invitation calendar (pro)',
  INVITED_USER: 'Invited User (pro)',
  IMPORT_LINKEDIN: 'Imported Linkedin Info (pro)',
  PUBLISHED_OFFER: 'Published Offer (pro)',
  DRAFTED_OFFER: 'Drafted Offer (pro)',
  DELETED_OFFER: 'Deleted Offer (pro)',
  SEARCHED_FOR_CANDIDATES_BY_OFFERS: 'Searched for Candidates by Offer',
  SET_APPOINTMENT: 'Set Appointment (pro)',
  CLICKED_DRAFT: 'Clicked draft',
  SWITCH_ACCOUNT: 'Switched Account (pro)',
  CLICKED_CONTACT_SALES: 'Clicked Contact Sales (pro)',
  CLICKED_AGENDA_VIEW: 'Edited calendar view (pro)',
  CANCELED_RECRUITER_PARTICIPATION: 'Canceled Recruiter Participation',
  CANCELED_COMPANY_PARTICIPATION: 'Canceled Company Participation',
  SORT_NEW_CANDIDATES: 'Searched by new candidat',
  CREATED_NEW_TEMPLATE: 'Created new template',
  UPDATED_TEMPLATE: 'Updated template',
  DELETED_TEMPLATE: 'Deleted template',
  USED_TEMPLATE: 'Used template',
  DOWNLOADED_APPOINTMENT_PLANNING: 'Downloaded Appointment Planning',
  SENT_NEGATIVE_EMAIL: 'Sent negative email',
  CLICKED_CONNECT_NYLAS: 'Clicked connect Nylas',
  CLICKED_DISCONNECT_NYLAS: 'Clicked disconnect Nylas',
  TOGGLE_SYNC_SLOTS: 'Toggle sync slots',
  TOGGLE_SYNC_APPOINTMENTS: 'Toggle sync appointments',
  CLICKED_REDIRECT_TO_SYNC: 'Clicked redirect to sync',
  EDITED_SYNC_CALENDAR: 'Edited sync calendar',
  DOWNLOADED_SEVERAL_CVS: 'Downloaded Several CVs',
  CLICKED_LEARN_PLAN: 'Clicked Learn More Plan',
  CLICKED_INFO_PLAN: 'Clicked Request Info Plan',
  CLICK_SEND_REQUEST_PLAN: 'Clicked Send Request Plan',
  EXPORTED_LIVES_DATA: 'Exported lives data (owner)',
  CLICKED_ACCESS_SCHOOL_EVENTS: 'Clicked access school events',
  CLICKED_DISCOVER_SCHOOL_EVENTS: 'Clicked discover school events',
  CLICKED_DISCOVER_OUR_OFFERS: 'Clicked discover our offers',
};

const ANALYTICS_CANDIDATE = {
  ADD_JOBDATING_TO_CALENDAR: "Add jobdating to calendar (candidate)",
  CLICKED_VIEW_KEY_DATES: 'Clicked View key dates',
  CLICKED_FORUM: 'Clicked See Forum',
  CLICKED_UPDATED_PROFILE: 'Clicked update profile',
  DECLINED_JOB_OFFER: 'Declined job offer',
  PARTICIPATION_SUBMIT: 'Edited Search',
  EDIT_SEARCH: 'Edited Search',
  CLICKED_EDITED_SEARCH: 'Clicked Edited Search',
  EDIT_PROFILE: 'Edited Profile',
  CANCEL_APPLICATION_FOR_JOB: 'Canceled Application For A Job',
  FILTER_OFFER: 'Searched For Offers',
  EDIT_PROFILE_CLICK: 'Clicked Edit Profile (candidate)',
  JOBDATING_CLICK: 'Clicked Voir Autres JD Recherche',
  OFFER_VISIT: 'v2-candidate-offer-visit',
  VIEW_OFFER_CLICK: 'Clicked View Offer',
  COMPANY_PICTURES_CLICK: 'Clicked View Company Pictures',
  COMPANY_VIDEOS_CLICK: 'Clicked View Company Videos',
  APPLICATION_CLICK: 'v2-candidate-application-click',
  APPLY_JOB_CLICK: 'Applied For A Job',
  APPLICATION_CONFIRMATION: 'Application confirmation',
  BOOK_SLOT: 'Booked a slot',
  EXPONENTS_VISIT: 'v2-candidate-exponent-visit',
  REGISTRATION_CLICK: 'v2-candidate-registration-click',
  PARTICIPATION_CLICK: 'Clicked Sign Up Forum DefaultRedirection Page',
  VIEW_COMPANY_CLICK: 'Clicked View Company',
  LANDING_SIGNIN_CLICK: 'Clicked Sign In Forum DefaultRedirection Page',
  TOUR_STEP1_SUBMIT: 'Filled In Presentation',
  TOUR_STEP2_SUBMIT: 'Filled In CV',
  TOUR_STEP3_SUBMIT: 'Filled In Formations & Expériences',
  TOUR_STEP4_SUBMIT: 'Filled In Recherche',
  TOUR_STEP4_LEFT: 'Left filled In Recherche',
  IMPORT_LINKEDIN: 'Imported Linkedin Info (candidate)',
  EXPORTED_APPOINTMENT_CALENDAR: 'Exported Appointment Calendar',
  CANCELED_APPOINTMENT: 'Canceled Appointment',
  SEND_EMAIL_RECRUITER_CALENDAR: 'Clicked Send Email Recruiter Calendar',
  QUICK_SIGNUP_STEP_1_SUBMIT: 'Filled In Recherche - Public',
  VISIT_ONBOARDING_SIGNUP: 'Visited filled in Sign Up',
  FILLED_ONBOARDING_SIGNUP: 'Filled in Sign Up',
  CLICKED_MODIFY_PARTICIPATION_MODE: 'Clicked modify participation mode',
  MODIFIED_PARTICIPATION_MODE: 'Modified participation mode',
};

const ANALYTICS_ALL = {
  EDIT_PROFILE: 'Edited Profile',
  LANDING_VISIT: 'v2-all-landing-visit',
  REGISTRATION: 'Signed Up',
  SIGN_IN: 'Signed in',
  SIGN_OUT: 'Logged Out',
  SETTINGS_CLICK: 'Clicked Settings',
  AUTHENTICATION: 'v2-all-authentication-submit',
  DELETE_ACCOUNT: 'Deleted account',
};

const TEMPLATES = {
  APPLICATION_VALIDATED: 'application-validated',
  APPLICATION_REFUSED: 'application-refused',
  PROPOSITION: 'proposition',
  PROPOSITION_JPO: 'proposition-jpo',
  EDIT_DATE: 'edit-date',
  EDIT_OWNER: 'edit-owner',
  CANCEL_INTERVIEW: 'cancel-interview',
  NEGATIVE_SCORING: 'interview-negative-scoring',
  EDIT_APPLICATION_OWNER: 'edit-application-owner'
};

const JOTFORM_URL = {
  admin: 'https://admin.typeform.com/form',
  edit: '/create',
  submissions: '/results',
  form: 'https://seekube.typeform.com/to/',
};

const CONTINENT_LOCATION = {
  AD: 'Europe',
  AE: 'Asie',
  AF: 'Asie',
  AG: 'Amérique du Nord',
  AI: 'Amérique du Nord',
  AL: 'Europe',
  AM: 'Asie',
  AN: 'Amérique du Nord',
  AO: 'Afrique',
  AR: 'Amérique du Sud',
  AS: 'Océanie',
  AT: 'Europe',
  AU: 'Océanie',
  AW: 'Amérique du Nord',
  AZ: 'Asie',
  BA: 'Europe',
  BB: 'Amérique du Nord',
  BD: 'Asie',
  BE: 'Europe',
  BF: 'Afrique',
  BG: 'Europe',
  BH: 'Asie',
  BI: 'Afrique',
  BJ: 'Afrique',
  BM: 'Amérique du Nord',
  BN: 'Asie',
  BO: 'Amérique du Sud',
  BR: 'Amérique du Sud',
  BS: 'Amérique du Nord',
  BT: 'Asie',
  BW: 'Afrique',
  BY: 'Europe',
  BZ: 'Amérique du Nord',
  CA: 'Amérique du Nord',
  CC: 'Asie',
  CD: 'Afrique',
  CF: 'Afrique',
  CG: 'Afrique',
  CH: 'Europe',
  CI: 'Afrique',
  CK: 'Océanie',
  CL: 'Amérique du Sud',
  CM: 'Afrique',
  CN: 'Asie',
  CO: 'Amérique du Sud',
  CR: 'Amérique du Nord',
  CU: 'Amérique du Nord',
  CV: 'Afrique',
  CX: 'Asie',
  CY: 'Asie',
  CZ: 'Europe',
  DE: 'Europe',
  DJ: 'Afrique',
  DK: 'Europe',
  DM: 'Amérique du Nord',
  DO: 'Amérique du Nord',
  DZ: 'Afrique',
  EC: 'Amérique du Sud',
  EE: 'Europe',
  EG: 'Afrique',
  EH: 'Afrique',
  ER: 'Afrique',
  ES: 'Europe',
  ET: 'Afrique',
  FI: 'Europe',
  FJ: 'Océanie',
  FK: 'Amérique du Sud',
  FO: 'Europe',
  FR: 'Europe',
  GA: 'Afrique',
  GB: 'Europe',
  TC: 'Amérique du Nord',
  TG: 'Afrique',
  TK: 'Océanie',
  TM: 'Asie',
  TN: 'Afrique',
  TO: 'Océanie',
  TR: 'Asie',
  TT: 'Amérique du Nord',
  TV: 'Océanie',
  UA: 'Europe',
  UG: 'Afrique',
  US: 'Amérique du Nord',
  UY: 'Amérique du Sud',
  UZ: 'Asie',
  VE: 'Amérique du Sud',
  VI: 'Amérique du Nord',
  VN: 'Asie',
  VU: 'Océanie',
  WF: 'Océanie',
  YE: 'Asie',
  ZM: 'Afrique',
  ZW: 'Afrique',
  AX: 'Europe',
  BQ: 'Amérique du Nord',
  IO: 'Asie',
  VG: 'Amérique du Nord',
  KH: 'Asie',
  KY: 'Amérique du Nord',
  TD: 'Afrique',
  KM: 'Afrique',
  HR: 'Europe',
  CW: 'Amérique du Nord',
  SV: 'Amérique du Nord',
  GQ: 'Afrique',
  GF: 'Amérique du Sud',
  PF: 'Océanie',
  GM: 'Afrique',
  GE: 'Asie',
  GH: 'Afrique',
  GI: 'Europe',
  GR: 'Europe',
  GL: 'Amérique du Nord',
  GD: 'Amérique du Nord',
  GP: 'Amérique du Nord',
  GU: 'Océanie',
  GT: 'Amérique du Nord',
  GG: 'Europe',
  GN: 'Afrique',
  GW: 'Afrique',
  GY: 'Amérique du Sud',
  HT: 'Amérique du Nord',
  VA: 'Europe',
  HN: 'Amérique du Nord',
  HK: 'Asie',
  HU: 'Europe',
  IS: 'Europe',
  IN: 'Asie',
  ID: 'Asie',
  IR: 'Asie',
  IQ: 'Asie',
  IE: 'Europe',
  IM: 'Europe',
  IL: 'Asie',
  IT: 'Europe',
  JM: 'Amérique du Nord',
  JP: 'Asie',
  JE: 'Europe',
  JO: 'Asie',
  KZ: 'Asie',
  KE: 'Afrique',
  KI: 'Océanie',
  KP: 'Asie',
  KR: 'Asie',
  KW: 'Asie',
  KG: 'Asie',
  LA: 'Asie',
  LV: 'Europe',
  LB: 'Asie',
  LS: 'Afrique',
  LR: 'Afrique',
  LY: 'Afrique',
  LI: 'Europe',
  LT: 'Europe',
  LU: 'Europe',
  MO: 'Asie',
  MK: 'Europe',
  MG: 'Afrique',
  MW: 'Afrique',
  MY: 'Asie',
  MV: 'Asie',
  ML: 'Afrique',
  MT: 'Europe',
  MH: 'Océanie',
  MQ: 'Amérique du Nord',
  MR: 'Afrique',
  MU: 'Afrique',
  YT: 'Afrique',
  MX: 'Amérique du Nord',
  FM: 'Océanie',
  MD: 'Europe',
  MC: 'Europe',
  MN: 'Asie',
  ME: 'Europe',
  MS: 'Amérique du Nord',
  MA: 'Afrique',
  MZ: 'Afrique',
  MM: 'Asie',
  NA: 'Afrique',
  NR: 'Océanie',
  NP: 'Asie',
  NL: 'Europe',
  NC: 'Océanie',
  NZ: 'Océanie',
  NI: 'Amérique du Nord',
  NE: 'Afrique',
  NG: 'Afrique',
  NU: 'Océanie',
  NF: 'Océanie',
  MP: 'Océanie',
  NO: 'Europe',
  OM: 'Asie',
  PK: 'Asie',
  PW: 'Océanie',
  PS: 'Asie',
  PA: 'Amérique du Nord',
  PG: 'Océanie',
  PY: 'Amérique du Sud',
  PE: 'Amérique du Sud',
  PH: 'Asie',
  PN: 'Océanie',
  PL: 'Europe',
  PT: 'Europe',
  PR: 'Amérique du Nord',
  QA: 'Asie',
  RE: 'Afrique',
  RO: 'Europe',
  RU: 'Europe',
  RW: 'Afrique',
  BL: 'Amérique du Nord',
  SH: 'Afrique',
  KN: 'Amérique du Nord',
  LC: 'Amérique du Nord',
  MF: 'Amérique du Nord',
  PM: 'Amérique du Nord',
  VC: 'Amérique du Nord',
  WS: 'Océanie',
  SM: 'Europe',
  ST: 'Afrique',
  SA: 'Asie',
  SN: 'Afrique',
  RS: 'Europe',
  SC: 'Afrique',
  SL: 'Afrique',
  SG: 'Asie',
  SX: 'Amérique du Nord',
  SK: 'Europe',
  SI: 'Europe',
  SB: 'Océanie',
  SO: 'Afrique',
  ZA: 'Afrique',
  SS: 'Afrique',
  LK: 'Asie',
  SD: 'Afrique',
  SR: 'Amérique du Sud',
  SJ: 'Europe',
  SZ: 'Afrique',
  SE: 'Europe',
  SY: 'Asie',
  TW: 'Asie',
  TJ: 'Asie',
  TZ: 'Afrique',
  TH: 'Asie',
  TL: 'Asie',
  UM: 'Océanie',
};

const ALIAS_MAILCHIMP = [
  'contrat',
  'duration',
  'availability',
  'position',
  'technos',
  'mobility',
  'level',
  'metier',
  'experience',
  'license',
  'sector',
  'size',
];

const TOPICS = {
  coaching: 'coaching',
  xp: 'xp',
  company: 'company',
  job: 'job',
  recruitment: 'recruitment',
  culture: 'culture',
  other: 'other',
};

const CONFERENCE_TOPICS = [
  TOPICS.coaching,
  TOPICS.xp,
  TOPICS.company,
  TOPICS.job,
  TOPICS.recruitment,
  TOPICS.culture,
  TOPICS.other,
];

const CONFERENCE_TOPICS_INDEX = {
  [TOPICS.coaching]: 9,
  [TOPICS.xp]: 4,
  [TOPICS.company]: 0,
  [TOPICS.job]: 2,
  [TOPICS.recruitment]: 3,
  [TOPICS.culture]: 5,
  [TOPICS.other]: 7,
};

/**
 * Colors
 */

const COLORS = {
  secondary: '#385077',
  white: '#ffffff',
};

/**
 * Locales
 */

const LOCALES = {
  fr: 'fr',
  en: 'en',
};

/**
 * Debounce in ms
 */

const DEBOUNCE = 300;

/**
 * Warning do no change order, only add item
 */


const TAG_COLORS = [
  'orange',
  'teal',
  'orchid',
  'blue',
  'pink',
  'yellow',
  'gold',
  'royalBlue',
  'cherry',
  'apple',
];

const MEETING_SERVICES = {
  bigmarker: {
    text: 'Bigmarker',
    patternInUrl: 'bigmarker.com',
  },
  teams: {
    text: 'Teams',
    patternInUrl: 'https://teams.microsoft.com',
  },
  googleMeet: {
    text: 'Google meet',
    patternInUrl: 'meet.google.com',
  },
  zoom: {
    text: 'Zoom',
    patternInUrl: 'https://zoom.us',
  },
  Webex: {
    text: 'Webex',
    patternInUrl: 'webex.com',
  },
  Skype: {
    text: 'Skype',
    patternInUrl: 'meet.lync',
  },
};

const SLOT_TYPE = {
  INTERVIEW: 'interview',
  INFORMAL1TO1: 'informal1to1',
  CONFERENCE: 'conference',
};


const INTERACTION_TYPE = {
  proposition: 'proposition',
  application: 'application',
  visitStand: 'visit-stand',
  applicationAuto: 'application-auto',
  informal1to1: 'informal1to1',
  informal1to1Pending: 'informal1to1-pending',
};

const APPOINTMENT_ORIGIN = {
  informal1to1: 'informal1to1',
  sourcing: 'sourcing',
  auto: 'auto',
}

const APPOINTMENT_STATUS = {
  ACCEPTED: 'accepted',
  ALREADY_INTERACTION: 'alreadyInteraction',
  DISABLED: 'applicationsDisabled',
  NO_DATE: 'nodate',
  NO_SLOT: 'noSlot',
  PENDING: 'pending',
  REFUSED: 'refused',
  SKIP: 'skip',
  UNCONFIRMED: 'unconfirmed',
  WAITING_LIST: 'waiting-list',
};

const CANDIDATE_JD_VIEWS = {
  exponents: 'exponents',
  jobs: 'jobs',
  appointments: 'appointments',
  informal1to1: 'informal1to1',
  informal1to1s: 'informal1to1s',
}

const ACL = {
  EDIT_USER: 'editUser',
  ADD_USER: 'addUser',
  ADD_EXPONENT_USER: 'addExponentUser',
  REMOVE_USER_FROM_ORGANIZATION: 'removeUserFromOrganization',
  REMOVE_USER_FROM_EXPONENT: 'removeUserFromExponent',
  EDIT_MASTER_STAND: 'editMasterStand',
  EDIT_EVENT_STAND: 'editStand',
  EDIT_ROLE: 'editRole',
  INVITE_USER: 'inviteUser',
  ATS: 'ats',
};

const CONTRACTS_WITHOUT_DURATION = ['CDI'];
const CONTRACTS_COMPATIBLE_WITH_NO_DURATION = ['CDD / Temporaire'];
const CONTRACTS_OTHERS = ['Stage', 'Stage de fin d\'études', 'Contrat d\'apprentissage', 'Contrat de professionalisation', 'Alternance', 'Stage Alterné'];

const SUPPORTED_CALENDARS = {
  'ical': 'Apple',
  'google': 'Google',
  'ms365': 'Outlook',
  'yahoo': 'Yahoo',
};

const CRITERIA = {
  CONTRACT: 'CONTRACT',
  MOBILITY: 'MOBILITY'
}

const API_CUSTOM_ERRORS = {
  passwordAlreadyUsed: 'password_policy_already_used',
  blockedAccount: 'blocked_account',
  wrongPassword: 'wrong_password',
  userNotFound: 'User not found',
  tokenAlreadyUsed: 'token_already_used',
  invalidCaptcha: 'invalid_captcha'
}

const PHONE_DEFAULT_INDICATORS = {
  fr: 'FR',
  en: 'GB'
}


const DATES_FORMAT = {
  fr: 'DD MMMM',
  en: 'MMMM DD'
}

const GTM_EVENTS = {
  generic: 'generic',
  virtualPageDisplay: 'virtual.page.display'
}

const GTM_EVENTS_NAME = {
  generic: 'generic',
  redirect: 'redirect'
}

const GTM_EVENTS_INFOS = {
  readMoreLanding: 'read_more_landing',
  viewOfferLanding: 'view_offer_landing',
  inscription: 'inscription',
  preinscription: 'preinscription'
}

const GTM_EVENT_PAGE_SUBJECT = {
  home: 'home',
  result: 'result'
}

const GTM_EVENT_PAGE_CATEGORY = {
  landing: 'landing',
  searchEvent: 'search_event'
}

/**
 * Default exports
 */
export {
  PARTICIPANT_ROLE,
  USER_ROLE,
  EVENT_STATUS,
  EVENT_FORMAT,
  ANALYTICS_CANDIDATE,
  ANALYTICS_RECRUITER,
  ANALYTICS_ORGA,
  ANALYTICS_ALL,
  CONTEXT_URLS,
  TEMPLATES,
  JOTFORM_URL,
  CONTINENT_LOCATION,
  ALIAS_MAILCHIMP,
  DISABLE_SERVICE_FOR_COMPANIES,
  INTERVIEW_CONTEXTS,
  ENABLE_SERVICE_FOR_TEST,
  LINKS,
  COLORS,
  LOCALES,
  DEBOUNCE,
  CONFERENCE_TOPICS,
  CONFERENCE_TOPICS_INDEX,
  TAG_COLORS,
  MEETING_SERVICES,
  USERS_TYPE,
  SLOT_TYPE,
  APPOINTMENT_ORIGIN,
  APPOINTMENT_STATUS,
  INTERACTION_TYPE,
  CANDIDATE_JD_VIEWS,
  DASHBOARD_MODE,
  EVENT_TYPE,
  ACL,
  CONTRACTS_WITHOUT_DURATION,
  CONTRACTS_COMPATIBLE_WITH_NO_DURATION,
  CONTRACTS_OTHERS,
  SUPPORTED_CALENDARS,
  USERS_EVENT_ROLES,
  WORDS_IN_SLUG_FOR_WOMEN_EVENT,
  API_CUSTOM_ERRORS,
  CRITERIA,
  PHONE_DEFAULT_INDICATORS,
  DATES_FORMAT,
  GTM_EVENTS,
  GTM_EVENTS_NAME,
  GTM_EVENTS_INFOS,
  GTM_EVENT_PAGE_SUBJECT,
  GTM_EVENT_PAGE_CATEGORY
};
