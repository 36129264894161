import { LOCALES } from './constants';

const LOCAL_STORAGE = {
  RECRUITER: {
    hideConfirmOnParticipantMove: 'hide_confirm_on_participant_move',
    hideConfirmInteractionSoonClosed: 'hide_confirm_interaction_soon_closed',
  },
  candidate: {
    jobAppoitmentsCriterias: 'job_appointments_criterias',
  },
  GLOBAL: {
    LOCALE: 'locale',
  },
  event: {
    signUpSuccess: 'signupSuccess'
  }
};

const setLocalStorage = (key, val) => {
  window.localStorage.setItem(key, val);
};

const getLocalStorage = (key) => window.localStorage.getItem(key);

const removeLocalStorage = (key) => window.localStorage.removeItem(key);

const getLocale = () => getLocalStorage(LOCAL_STORAGE.GLOBAL.LOCALE) || LOCALES.fr;

export {
  setLocalStorage,
  getLocalStorage,
  removeLocalStorage,
  getLocale,
  LOCAL_STORAGE,
};
